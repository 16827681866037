import { Currency, Token, computePoolAddress } from '@cryptoalgebra/scribe-sdk';
import { useMemo } from 'react';
import { useAllCurrencyCombinations } from './useAllCurrencyCombinations';
import { Address } from '@thirdweb-dev/sdk';
import { useMultiplePoolsQuery } from '@app/hooks/v3/useMultiplePoolsQuery';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

/**
 * Returns all the existing pools that should be considered for swapping between an input currency and an output currency
 * @param currencyIn the input currency
 * @param currencyOut the output currency
 */
export function useSwapPools(currencyIn?: Currency, currencyOut?: Currency) {
  const allCurrencyCombinations = useAllCurrencyCombinations(
    currencyIn,
    currencyOut
  );
  const chainId = useSupportedChain()?.chainId;

  const poolsAddresses = allCurrencyCombinations.map(
    ([tokenA, tokenB]) =>
      computePoolAddress({
        tokenA,
        tokenB
        // initCodeHashManualOverride:
        //   ALGEBRA_ADDRESSES[config.modeEnv].POOL_INIT_CODE_HASH,
        // poolDeployer: ALGEBRA_ADDRESSES[config.modeEnv].ALGEBRA_POOL_DEPLOYER
      }) as Address
  );

  const { data: existingPools } = useMultiplePoolsQuery({
    poolIds: poolsAddresses
      .map(address => {
        return `"${address.toLowerCase()}"`;
      })
      .join(',')
  });

  return useMemo(() => {
    if (!existingPools || !chainId)
      return {
        pools: [],
        loading: true
      };

    return {
      pools: existingPools
        .map(pool => ({
          tokens: [
            new Token(
              chainId,
              pool.token0.id,
              Number(pool.token0.decimals),
              pool.token0.symbol,
              pool.token0.name
            ),
            new Token(
              chainId,
              pool.token1.id,
              Number(pool.token1.decimals),
              pool.token1.symbol,
              pool.token1.name
            )
          ] as [Token, Token],
          pool: pool
        }))
        .filter(({ pool }) => {
          return pool;
        }),
      loading: false
    };
  }, [chainId, existingPools]);
}
