import { useQuery } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { Pool, PoolToken } from '@app/types/pool';
import { getConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

type QueryParams = {
  poolIds: string;
};

type Response = {
  address: string;
  liquidity: string;
  price: string;
  tick: string;
  token0: PoolToken;
  token1: PoolToken;
  fee: string;
};

function getQuery(queryParams: QueryParams) {
  return gql`
      query {
        pools(where: {id_in: [${queryParams.poolIds}]}) {
          id
          token0 {
            id
            totalSupply
            symbol
            name
            decimals
            txCount
          }
          token1 {
            id
            totalSupply
            symbol
            name
            decimals
            txCount
          }
          id
          liquidity
          tick
          fee
          totalValueLockedUSD
          volumeUSD
          sqrtPrice
        }
      }
    `;
}

const fetcher = async (
  queryParams: QueryParams,
  chainId: number | undefined
) => {
  const query = getQuery(queryParams);

  const config = getConfig(chainId);

  if (!config) {
    return null;
  }

  const res = await request<{ pools: Pool[] }>(
    `${config?.URLS.subgraphUrlV3}`,
    query,
    queryParams
  );

  return res.pools.map(pool => {
    return {
      address: pool.id,
      liquidity: pool.liquidity,
      price: pool.sqrtPrice,
      tick: pool.tick,
      token0: pool.token0,
      token1: pool.token1,
      fee: pool.fee
    };
  });
};

export function useMultiplePoolsQuery(params: QueryParams) {
  const chainId = useSupportedChain()?.chainId;

  return useQuery<Response[] | undefined | null>(
    [QueryKeys.MULTIPLE_POOLS, { params, chainId }],
    () => {
      return fetcher(params, chainId);
    },
    {
      staleTime: 5000,
      refetchOnWindowFocus: false,
      enabled: !!chainId
    }
  );
}
