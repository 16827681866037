import { Currency, Percent, Trade, TradeType } from '@cryptoalgebra/scribe-sdk';
import { useSwapCallArguments } from './useSwapCallArguments';
import { useMemo } from 'react';
import { SwapCallbackState } from 'src/types/swap-state';
import { SmartContract, useContract } from '@thirdweb-dev/react';

import { algebraRouterABI } from '../../../abis/v3';
import { useAsyncFn } from 'react-use';
import { useConfig } from '@app/config';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { ethers } from 'ethers';

async function fetcher(
  swapCalldata: { calldata: any; value: any }[],
  algebraRouter: SmartContract<ethers.BaseContract> | undefined
) {
  if (!swapCalldata || !algebraRouter || !swapCalldata.length) {
    return null;
  }

  const _calls = await Promise.allSettled(
    swapCalldata.map(async call => {
      const _config = algebraRouter.prepare('multicall', [call.calldata], {
        value: BigInt(call?.value || 0)
      });

      try {
        const res = await _config.estimateGasLimit();

        return { gasEstimate: res.toString(), call };
      } catch (e) {
        return { error: 'Error estimate gas', call };
      }
    })
  );
  const calls = _calls.reduce<CallResult[]>((r, item) => {
    if (item.status === 'fulfilled') {
      r.push(item.value as CallResult);
    }

    return r;
  }, []);

  type CallResult = {
    gasEstimate: string;
    call: { calldata: any; value: any };
  };

  let bestCallOption: CallResult | undefined = calls.find(
    (el, ix, list): el is CallResult =>
      'gasEstimate' in el &&
      (ix === list.length - 1 || 'gasEstimate' in list[ix + 1])
  );

  if (!bestCallOption) {
    const firstNoErrorCall = calls.find<any>(
      (call): call is any => !('error' in call)
    );

    if (!firstNoErrorCall) {
      throw new Error('Unexpected error. Could not estimate gas for the swap.');
    }

    bestCallOption = firstNoErrorCall;
  }

  return bestCallOption;
}

export function useSwapCallback(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: Percent,
  enabled: boolean
) {
  const swapCalldata = useSwapCallArguments(trade, allowedSlippage);

  const config = useConfig();

  const { contract: algebraRouter } = useContract(
    config?.ALGEBRA_ADDRESSES.ALGEBRA_ROUTER,
    algebraRouterABI
  );

  const calldataKey = swapCalldata.reduce(
    (r, k) => r + `_${k.calldata[0]}_${k.value}`,
    ''
  );

  const { data: value } = useQuery(
    [QueryKeys.SWAP_BEST_CALL, calldataKey],
    () => fetcher(swapCalldata, algebraRouter),
    {
      staleTime: 5000,
      refetchOnWindowFocus: false,
      enabled: Boolean(swapCalldata && enabled && algebraRouter)
    }
  );

  const [{ loading }, swapCallback] = useAsyncFn(async () => {
    if (!algebraRouter || !value) {
      return;
    }

    const { call, gasEstimate } = value;

    return await algebraRouter.call('multicall', [call.calldata], {
      value: BigInt(call?.value || 0),
      gasLimit: gasEstimate
        ? Math.ceil((+gasEstimate * (10000 + 2000)) / 10000)
        : undefined
    });
  }, [algebraRouter, swapCalldata, value]);

  return useMemo(() => {
    if (!trade)
      return {
        state: SwapCallbackState.INVALID,
        callback: null,
        error: 'No trade was found',
        isLoading: false
      };

    return {
      state: SwapCallbackState.VALID,
      callback: swapCallback,
      error: null,
      isLoading: loading
    };
  }, [loading, swapCallback, trade]);
}
