import { Currency, CurrencyAmount } from '@cryptoalgebra/scribe-sdk';
import { SwapCallbackState } from 'src/types/swap-state';
import { useContract, useSDK } from '@thirdweb-dev/react';
import { useAsyncFn } from 'react-use';
import { SwapField, SwapFieldType } from '@app/types/swap-field';
import { numberToBigNumberFixed } from '@app/helpers/format';
import wethAbi from '../../../abis/weth.json';
import { useConfig } from '@app/config';

export function useWrapCallback(
  currencies: {
    [field in SwapFieldType]?: Currency;
  },
  parsedAmount: CurrencyAmount<Currency> | undefined
) {
  const sdk = useSDK();
  const config = useConfig();

  const { contract: wethContract } = useContract(
    config?.CONTRACTS.WETH,
    wethAbi
  );

  const from = currencies[SwapField.INPUT];
  const to = currencies[SwapField.OUTPUT];

  const amount = parsedAmount?.toSignificant();

  const [{ loading, error }, wrapCallback] = useAsyncFn(async () => {
    if (!to || !from || !wethContract) {
      return undefined;
    }

    if (!amount) {
      return;
    }

    if (
      // ETH -> WETH
      from?.isNative &&
      to?.wrapped.address === config?.CONTRACTS.WETH
    ) {
      return sdk?.wallet.transfer(config?.CONTRACTS.WETH, amount);
    } else if (
      // WETH -> ETH
      from?.wrapped.address === config?.CONTRACTS.WETH &&
      to.isNative
    ) {
      return wethContract.call('withdraw', [
        numberToBigNumberFixed(+amount, 18)
      ]);
    }
  }, [amount, to, from, wethContract, sdk]);

  return {
    state: SwapCallbackState.VALID,
    callback: wrapCallback,
    error: null,
    isLoading: loading
  };
}
