import { ArrowUpDownIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import React, { FC } from 'react';

interface Props {
  onClick: () => void;
}

export const SwapButton: FC<Props> = ({ onClick }) => {
  return (
    <Box position="relative" h="1px">
      <Button
        onClick={onClick}
        w={['28px', '56px']}
        h={['28px', '56px']}
        borderRadius="50%"
        border="2px solid"
        borderColor="neutral.200"
        position="absolute"
        top={['-14px', '-28px']}
        left="50%"
        transform="translateX(-50%)"
        bg="neutral.400"
        color="neutral.200"
        _hover={{
          filter: 'brightness(120%)'
        }}
      >
        <ArrowUpDownIcon boxSize={4} />
      </Button>
    </Box>
  );
};
