import React, { FC } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { Dividends } from '@app/components/Dividends';
import { SubmitButton } from '@app/components/SubmitButton';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  message?: string;
  title?: string;
  onSubmit: () => void;
}

export const ConfirmModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  message
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="xs"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '586px'], padding: '16px' }}>
        <ModalHeader sx={{ fontSize: '20px' }}>{title}</ModalHeader>
        <Text fontSize={16} color="neutral.100" mt={5}>
          {message}
        </Text>
        <ModalFooter
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            mt: '48px'
          }}
        >
          <Button
            variant="secondary"
            py="10px"
            px="20px"
            borderRadius="12px"
            onClick={() => {
              onClose();
            }}
            w="100%"
          >
            <Text
              fontSize="16px"
              fontFamily="'Inter', sans-serif"
              fontWeight="500"
              lineHeight="20px"
            >
              Cancel
            </Text>
          </Button>
          <SubmitButton
            isLoading={false}
            label="Confirm"
            onClick={onSubmit}
            mt={0}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
