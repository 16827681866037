import { RepeatIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Text,
  Button,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import React, { FC, useState, useEffect } from 'react';

import { useUserState } from '@app/state/userStore';
import { decToFrac } from '@app/helpers/format';
import { Percent } from '@cryptoalgebra/scribe-sdk';

interface Props {
  onRefresh?: () => void;
}

function getSlippage() {
  const slippage = localStorage.getItem('SWAP_SLIPPAGE');

  if (slippage && slippage !== 'auto') {
    const frac = decToFrac(+slippage / 100);

    return { storeValue: new Percent(frac[0], frac[1]), value: slippage };
  }

  return {
    storeValue: 'auto' as const,
    value: ''
  };
}

function getDeadline() {
  const deadline = localStorage.getItem('SWAP_DEADLINE');

  if (deadline) {
    return { storeValue: Number(deadline), value: deadline };
  }

  return {
    storeValue: 180,
    value: String(180)
  };
}

export const HeaderControlsV3: FC<Props> = ({ onRefresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deadline, setDeadline] = useState('');
  const [slp, setSlp] = useState('');
  const [mode, setMode] = useState('manual');

  const {
    actions: { setSlippage, setTxDeadline }
  } = useUserState();

  useEffect(() => {
    const d = getDeadline();

    setDeadline(d.value);
    setTxDeadline(d.storeValue);

    const s = getSlippage();

    setSlp(s.value);
    setSlippage(s.storeValue);

    if (s.storeValue === 'auto') {
      setMode('auto');
    }
  }, [setSlippage, setTxDeadline]);

  return (
    <HStack w="100%" justify="flex-end" mb="4px" gap="16px">
      {onRefresh && (
        <IconButton
          aria-label="refresh"
          icon={
            <RepeatIcon
              w="18px"
              h="18px"
              color="neutral.300"
              transition="color 0.2 ease"
              _hover={{ color: 'white', cursor: 'pointer' }}
            />
          }
          onClick={onRefresh}
        />
      )}
      <IconButton
        aria-label="refresh"
        p="12px"
        border="1px solid"
        borderRadius="8px"
        borderColor="whiteOpacity.6"
        icon={
          <SettingsIcon
            w="18px"
            h="18px"
            color="neutral.300"
            transition="color 0.2 ease"
            _hover={{ color: 'white', cursor: 'pointer' }}
          />
        }
        onClick={onOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        isCentered
        motionPreset="slideInBottom"
        size="xs"
      >
        <ModalOverlay sx={{ bg: 'blackAlpha.700' }} />
        <ModalContent
          sx={{ maxWidth: ['100vw', '360px'], backgroundColor: 'neutral.700' }}
        >
          <ModalHeader>
            <HStack justify="space-between" px={0} py={0} mb="16px">
              <Text fontSize="20px" color="brand.primary" fontWeight="500">
                Settings
              </Text>
              <ModalCloseButton
                onClick={() => {
                  onClose();
                }}
              />
            </HStack>
          </ModalHeader>
          <VStack w="100%">
            <Box mb="16px" w="100%">
              <Text
                textAlign="left"
                w="100%"
                color="neutral.200"
                fontSize="16px"
                fontFamily="p"
                mb="4px"
              >
                Slippage (%)
              </Text>
              <HStack>
                <Input
                  variant="filled"
                  borderRadius="8px"
                  fontSize="xl"
                  border="none"
                  bg="brand.black"
                  p="4px 12px"
                  fontFamily="p"
                  color="brand.white"
                  gridArea="input"
                  _focus={{
                    outline: 'none'
                  }}
                  _disabled={{
                    opacity: 0.5
                  }}
                  w="100%"
                  // type="number"
                  step={0.1}
                  value={mode === 'auto' ? 'auto' : slp}
                  disabled={mode === 'auto'}
                  onChange={e => {
                    const val = e.target.value;

                    setSlp(val);
                  }}
                  placeholder="0"
                />
                <HStack
                  w="fit-content"
                  alignItems="flex-start"
                  gap="3px"
                  px="4px"
                  py="4px"
                  sx={{
                    background: 'brand.black',
                    borderRadius: '24px',
                    p: '4px 12px'
                  }}
                >
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      setMode('manual');
                    }}
                    sx={{
                      border: 0,
                      minW: '60px',
                      fontSize: 12,
                      padding: '4px 12px',
                      color: mode === 'manual' ? 'brand.black' : 'neutral.200',
                      bg: mode === 'manual' ? 'brand.primary' : 'transparent'
                    }}
                    size="md"
                  >
                    Manual
                  </Button>
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      setMode('auto');
                    }}
                    sx={{
                      border: 0,
                      minW: '60px',
                      fontSize: 12,
                      padding: '4px 12px',
                      color: mode === 'auto' ? 'brand.black' : 'neutral.200',
                      bg: mode === 'auto' ? 'brand.primary' : 'transparent'
                    }}
                    size="md"
                  >
                    Auto
                  </Button>
                </HStack>
              </HStack>
            </Box>
            <Box mb="16px" w="100%">
              <Text
                textAlign="left"
                w="100%"
                color="neutral.200"
                fontSize="16px"
                fontFamily="p"
                mb="4px"
              >
                Deadline (min)
              </Text>
              <Input
                variant="filled"
                borderRadius="8px"
                fontSize="xl"
                border="none"
                bg="brand.black"
                p="4px 12px"
                fontFamily="p"
                color="brand.white"
                gridArea="input"
                _focus={{
                  outline: 'none'
                }}
                w="100%"
                type="number"
                value={deadline}
                onChange={e => setDeadline(e.target.value)}
                placeholder="0"
              />
            </Box>
          </VStack>
          <ModalFooter justifyContent="space-between" gap="16px">
            <Button
              variant="secondary"
              w="100%"
              onClick={async () => {
                setSlippage('auto');
                setDeadline(String(180));
              }}
            >
              Reset to defaults
            </Button>
            <Button
              variant="primary"
              w="100%"
              onClick={async () => {
                if (mode === 'manual') {
                  const frac = decToFrac(+slp / 100);

                  localStorage.setItem('SWAP_SLIPPAGE', slp);
                  setSlippage(new Percent(frac[0], frac[1]));
                } else if (mode === 'auto') {
                  localStorage.setItem('SWAP_SLIPPAGE', 'auto');
                  setSlippage('auto');
                }

                localStorage.setItem('SWAP_DEADLINE', deadline);
                setTxDeadline(Number(deadline));

                onClose();
              }}
            >
              Save settings
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};
