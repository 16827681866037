import { HStack, VStack, Text } from '@chakra-ui/react';
import { useBalance } from '@thirdweb-dev/react';
import React, { FC, useCallback, useMemo } from 'react';

import { DebouncedInput } from '@app/components/DebouncedInput';
import { TokenSelector } from '@app/components/Swap/components/TokenSelector';
import { Currency, Percent } from '@cryptoalgebra/scribe-sdk';
import { SwapFieldType } from '@app/types/swap-field';
import { Address } from '@thirdweb-dev/sdk';
import { formatCurrency } from '@app/helpers/formatCurrency';
import {
  getCurrencyFromKimToken,
  getKimTokenFromCurrency
} from '@app/helpers/currency';
import { formatUSD } from '@app/helpers/formatUSD';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

interface Props {
  label: string;
  handleTokenSelection: (currency: Currency) => void;
  handleValueChange: (value: string) => void;
  handleMaxValue?: () => void;
  value: string;
  currency: Currency | null | undefined;
  otherCurrency: Currency | null | undefined;
  fiatValue: number | undefined;
  priceImpact: Percent | undefined;
  showMaxButton?: boolean;
  field: SwapFieldType;
  filterByPools?: boolean;
}

export const TokenInputV3: FC<Props> = ({
  handleValueChange,
  value,
  currency,
  fiatValue,
  label,
  handleTokenSelection,
  filterByPools
}) => {
  const { data: balance, isLoading } = useBalance(
    currency?.isNative ? undefined : (currency?.wrapped.address as Address)
  );
  const chainId = useSupportedChain()?.chainId;

  const balanceString = useMemo(() => {
    if (isLoading || !balance) return 'Loading...';

    return formatCurrency.format(Number(balance.displayValue));
  }, [balance, isLoading]);

  const handleInput = useCallback(
    (value: string) => {
      if (value === '.') value = '0.';

      handleValueChange(value);
    },
    [handleValueChange]
  );

  return (
    <VStack
      w="100%"
      bg="neutral.700"
      transition="background 0.2s ease"
      px="16px"
      py="12px"
      borderRadius="12px"
      overflow="hidden"
    >
      <Text
        textAlign="left"
        w="100%"
        color="neutral.400"
        fontSize="12px"
        fontFamily="p"
      >
        {label}
      </Text>
      <HStack w="100%" mb="4px">
        <TokenSelector
          onSelect={val => {
            if (!chainId) {
              return;
            }

            const _currency = getCurrencyFromKimToken(val, chainId);

            if (!_currency) {
              return;
            }

            handleTokenSelection(_currency);
          }}
          disabled={false}
          selected={getKimTokenFromCurrency(currency)}
          filterByPools={filterByPools}
          isV3
        />
        <DebouncedInput
          amount={value}
          disabled={false}
          onChange={handleInput}
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            color: 'neutral.200',
            fontFamily: 'p'
          }}
        />
      </HStack>
      <HStack w="100%" justify="space-between">
        <HStack justify="flex-start">
          <Text
            color="neutral.200"
            fontSize="12px"
            fontWeight="400"
            fontFamily="p"
          >
            Balance:
          </Text>
          <Text
            color="neutral.200"
            fontSize="12px"
            fontWeight="400"
            fontFamily="p"
          >
            {currency && balanceString ? balanceString : 'n/a'}
          </Text>
        </HStack>
        <Text
          color="neutral.200"
          fontSize="12px"
          fontWeight="400"
          fontFamily="p"
        >
          {(fiatValue && formatUSD.format(fiatValue)) ?? '$0'}
        </Text>
      </HStack>
    </VStack>
  );
};
